import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../../features/hooks/useLocalStorage";
import {AuthContext} from '../AuthContext';


const getAuthContext = () => {
    /* This function gets a current localstorage object by key - authContext.
    Result this function is an init state for AuthContex */

    const authContextString = localStorage.getItem('authEmployerContext');
    return JSON.parse(authContextString) || null;
}

const AuthRequired = ({children}) => {
    /* Custom Auth controller component  */

    const navigate = useNavigate();
    const {authContext} = useContext(AuthContext);

    useEffect(() => {
        if (authContext === null) {
            navigate('/login');
        }
    }, [authContext]);

    return children;
}

const AuthProvider = ({children}) => {
    const [isAuth, setIsAuth] = useLocalStorage('authEmployerContext', getAuthContext()); // get default value from LocalStorage

    const [authContext, setAuthContext] = useState(isAuth);

    const login = async (tokens, employerData) => {
        /*
        This function waiting from Login component two params:
        @tokens object
        @patientData an object   */

        setIsAuth({...tokens, employerData});  // save to localstorage
        setAuthContext({...tokens, employerData}); // save tokens and parse user_id
    }

    const login_for_employee_with_role = async (tokens, employerData, employeeData) => {
        /* EmployerEmployee in da house  */

        setIsAuth({...tokens, employerData, isEmployeeAuth: true, employeeData});  // save to localstorage
        setAuthContext({...tokens, employerData, isEmployeeAuth: true, employeeData}); // save tokens and parse user_id
    }

    const authConfig = {
        /* headers object for all secure requests. `ECL` it's not standard, but it's custom token for security. */

        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const fileAuthConfig = {
        /* headers object for all secure requests. `ECL` it's not standard, but it's custom token for security. */

        headers: {
            Authorization: `ECL ${authContext?.access}`,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    const updateEmployerData = newStateData => {

        setIsAuth(prevState => {
            const {employerData} = prevState;
            return {...prevState, employerData: {...employerData, ...newStateData}};
        });

    }

    // const createShippingInfo = newShippingData => {
    //
    //     setIsAuth(prevState => {
    //         const {patientData} = prevState;
    //
    //         return {
    //             ...prevState, patientData: {
    //                 ...patientData, shipping_addresses: [{...newShippingData}]
    //             }
    //         };
    //     });
    //
    // }
    //
    // const updateShippingInfo = newShippingData => {
    //
    //     setIsAuth(prevState => {
    //         const {patientData} = prevState;
    //         const {shipping_addresses} = patientData;
    //         const filteredShippingInfos = shipping_addresses.filter(address => address.default !== true);  // at this moment I'm creating only for `default` address
    //
    //         return {
    //             ...prevState, patientData: {
    //                 ...patientData, shipping_addresses: [{...newShippingData}, ...filteredShippingInfos]
    //             }
    //         };
    //     });
    //
    // }

    const logout = () => {
        /* Logout function. Clear all localstorage keys and turn off AuthContex */
        setAuthContext(null); // change react context
        setIsAuth(null); // change localstorage
    }

    return <AuthContext.Provider
        value={
            {
                authContext, authConfig, fileAuthConfig,
                login, login_for_employee_with_role, logout,
                updateEmployerData,
            }
        }>
        {children}
    </AuthContext.Provider>
}


export {AuthProvider, AuthRequired}