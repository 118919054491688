import dayjs from "dayjs";
import React, {useContext, useState, useEffect} from "react";
import MainPageTitle from "../../../components/MainPageTitle";


import {AuthContext} from "../../auth/AuthContext";
import restAPI from "../../../features/restAPI";
import {authPages} from "../../../routing/config";

import {Col, List, Row, Skeleton, App, Spin, Space, Divider, Button, Typography, Popconfirm, message} from "antd";
import {CalendarOutlined, LoadingOutlined, UserOutlined, RiseOutlined, DeleteOutlined} from "@ant-design/icons";

import EmployerEmployeesDownloaderModal from "./EmployerEmployeesDownloaderModal";
import EmployeeEditModal from "./EmployeeEditModal";


const EmployeesManagementEmails = () => {
    const {
        employees
    } = authPages;

    const {message} = App.useApp();

    const [isLoading, setIsLoading] = useState(true);
    const {authContext} = useContext(AuthContext); // auth data from current session
    const [employeesList, setEmployeesList] = useState(null);
    const [activeContract, setActiveContract] = useState(null);

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const removeEmployeeFromListAfterDelete = (employeeAuthUserID) => {
        const newEmployeesList = employeesList.filter(employee => employee?.auth_user !== employeeAuthUserID);
        setEmployeesList(newEmployeesList);
    }


    const deleteAllEmployees = async () => {
        try {
            setIsLoading(true);

            const deletePromises = employeesList.map(employee => restAPI.delete(`/users/${employee.auth_user}/`, authConfig));

            await Promise.all(deletePromises);

            setEmployeesList([]);
            message.success('All employees have been deleted');
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const topUpBalance = async () => {
        try {
            setIsLoading(true);
            const response = await restAPI.post(`/contracts/${activeContract?.id}/top_up_employees/`, {}, authConfig);
            message.success('Balance has been topped up! And sending emails has been started!');
            console.log(response);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const loadActiveEmployerContract = async () => {
        try {
            setIsLoading(true);

            const response = await restAPI.get(`/contracts/?client=${authContext?.employerData?.id}&active=1`, authConfig)

            console.log(response.data.results[0]);

            setActiveContract(response.data.results[0])

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const loadAllEmployees = async () => {

        try {
            const apiUrl = `/employer-employees/?client=${authContext?.employerData?.id}`;
            const response = await restAPI.get(apiUrl, authConfig);

            setEmployeesList(response?.data?.results);
            setIsLoading(false);

        } catch (error) {
            console.log(error);

            if (error.code === "ERR_BAD_REQUEST" && error?.response?.status === 401) {
                message.error('Your session has expired. Please, login again.');
                authContext?.setAuthContext(null);
            }
        }
    };

    const [productsList, setProductsList] = useState([]);

    const loadProductList = async () => {
        setIsLoading(true);

        await restAPI.get('/products/', authConfig)
            .then(response => {

                const customProductsForFilteringComponent = response?.data?.results.map(
                    product => ({
                        ...product,
                        label: product?.name,
                        value: product?.name,
                        key: product?.id,
                    }));

                const addShowAll = [{
                    key: 1000,
                    label: 'Show all',
                    value: 'all',
                    code: 'all'
                }, ...customProductsForFilteringComponent];

                setProductsList(addShowAll);

            }).catch(err => {
                console.log(err);
                setProductsList([]);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        (async () => {
            await Promise.all([loadAllEmployees(), loadActiveEmployerContract()])
        })();
        // eslint-disable-next-line
    }, []);

    // const [options, setOptions] = useState([]);


    const [open, setOpen] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onClick = ({key}) => {
        message.info(`Click on item ${key}`);
    };

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{employees.title}</MainPageTitle>
            </Col>

            <Col xs={24} lg={24}>

                <Space size={'middle'}>

                    {activeContract === undefined || activeContract === null
                        ? <Typography.Text type="secondary" style={{marginLeft: 10}}>
                            System waiting for the contract activation
                        </Typography.Text>
                        : null
                    }

                    <EmployerEmployeesDownloaderModal
                        activeContract={activeContract}
                        authContext={authContext}
                        setEmployeesList={setEmployeesList}
                    />

                    {/*todo - Top up balance - modal with explanations*/}
                    <Popconfirm
                        title="Top up the balance"
                        description={<>Top up the balance of each employee based on your contract<br/> and send out
                            emails to everyone with login information?</>}
                        onConfirm={topUpBalance}
                        okText="Lets's do it!"
                        cancelText="No, I need more time"
                    >
                        <Button
                            size={'small'}
                            hidden={activeContract === null || activeContract === undefined || employeesList?.length === 0}
                            disabled={activeContract?.is_signed === true}
                        >
                            <RiseOutlined/> Top up balance
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        title="Delete all"
                        description="Are you sure to delete  all employees with their accounts?"
                        onConfirm={deleteAllEmployees}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            danger
                            size={'small'}
                            hidden={employeesList?.length === 0}
                        >
                            <DeleteOutlined/> Delete all employees
                        </Button>
                    </Popconfirm>

                </Space>

                <Divider/>

                <List
                    loading={isLoading}
                    itemLayout="horizontal"
                    dataSource={employeesList || []}
                    renderItem={employee => (
                        <List.Item
                            key={employee?.id}
                            actions={[
                                <EmployeeEditModal
                                    {...employee}
                                    authConfig={authConfig}
                                    update={setEmployeesList}
                                    removeEmployeeFromListAfterDelete={removeEmployeeFromListAfterDelete}
                                />,
                                <p><CalendarOutlined/> Created at: {dayjs(employee?.created).format('YYYY-MM-DD')}</p>,
                            ]}
                        >
                            <Skeleton avatar title={false} loading={isLoading} active>
                                <List.Item.Meta
                                    avatar={<UserOutlined style={{fontSize: 40}}/>}
                                    title={<span>{employee?.first_name} {employee?.last_name}</span>}
                                    description={<Space>
                                        {/*<>Status: {item?.active ? "Active" : "Disabled"}</>*/}
                                        {/*<>Patient portal: {item?.role}</>*/}
                                        <>Role: {employee?.role}</>
                                        {/*<Divider type="vertical"/>*/}
                                        {/*<>Corporate balance: ${item?.employer_balance}</>*/}
                                    </Space>}
                                />
                                <div>

                                </div>
                            </Skeleton>
                        </List.Item>
                    )}
                />

            </Col>
        </Row>
    </Spin>
}

export default EmployeesManagementEmails;