import restAPI from "../../../features/restAPI";
import React from "react";
import {Button, message, Popconfirm} from "antd";


const DeleteEmployerWithPatientAccountButton = ({userID, removeEmployeeFromListAfterDelete, authConfig}) => {
    const deleteEmployerWithPatientAccountHandler = async () => {
        try {
            const response = await restAPI.delete(`/users/${userID}/`, authConfig);
            removeEmployeeFromListAfterDelete(userID);
            message.success('Employer with patient account was deleted');
        } catch (error) {
            console.log(error);
        }
    }

    return <Popconfirm
        title="Delete"
        description="Are you sure to delete this employee?"
        onConfirm={deleteEmployerWithPatientAccountHandler}
        okText="Yes"
        cancelText="No"
    >
        <Button
            size={'small'}
            type="primary"
            danger
        >
            Delete all info about user
        </Button>
    </Popconfirm>;
}

export default DeleteEmployerWithPatientAccountButton;