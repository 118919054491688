import restAPI from "../../../features/restAPI";
import React, {useState, useEffect, useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {UploadOutlined} from "@ant-design/icons";
import {Button, Modal, Upload, App, Divider} from 'antd';

import {useTaskStatus} from '../../tasks/TaskStatusContext';
import {AuthContext} from "../../auth/AuthContext";


const EmployerEmployeesDownloaderModal = ({activeContract}) => {

    let navigate = useNavigate();
    const {fileAuthConfig, authContext} = useContext(AuthContext); // load app data
    const {employerData} = authContext;
    const {message} = App.useApp();

    const formData = new FormData();
    const [open, setOpen] = useState(false);  // File Upload Modal window
    const [fileCSV, setFileCSV] = useState(null);
    const [myFileList, setMyFileList] = useState(null);
    const [uploading, setUploading] = useState(false);


    // Works with errors in the Modal window
    const [modal, contextHolder] = Modal.useModal();

    // Long task status
    const {setTaskID} = useTaskStatus();

    const showModal = () => {
        setOpen(true);
    };

    const handleFileModalCancel = () => {
        setOpen(false);
    };

    const handleUpload = async () => {

        try {

            setUploading(true);
            const response = await restAPI.post('/upload-employees/', formData, fileAuthConfig);
            handleFileModalCancel();  // close modal window

            // *** Set task ID for long task status
            setTaskID(response?.data?.task_id);

            message.loading(`Your task accepted! Loading in progress ...`, 1);

        } catch (errors) {

            const {data} = errors?.response;

            // *** I'm showing only 5 errors in the Modal window
            if (data.hasOwnProperty('errors') && data.hasOwnProperty('total_errors')) {
                modal.error({
                    okText: 'I got it',
                    closable: true,
                    width: "50%",
                    title: 'Employees file upload failed.',
                    content: <>
                        <Divider/>
                        <h4>Total errors: {data.total_errors}</h4>

                        {data.errors.slice(0, 5).map(error_item => <>
                            <Divider/>
                            <span style={{fontSize: 12}}>{error_item}</span>
                        </>)}

                        <Divider/>
                        <div>...</div>
                    </>
                });

                return;
            }

            // *** 500 error
            if (data.hasOwnProperty('error') && data.hasOwnProperty('message')) {
                modal.error({
                    okText: 'I got it',
                    closable: true,
                    width: "50%",
                    title: 'Employees file upload failed.',
                    content: <>
                        <Divider/>
                        <span style={{fontSize: 12}}>{data.message}</span>
                    </>
                });

                return;
            }

            if (errors?.response?.status === 401) {
                navigate('/session-expired');
            } else {
                console.log("EmployerTestSettingsModal Error", errors);
            }

            handleFileModalCancel();

        } finally {
            setFileCSV(null);
            setMyFileList(null);
            setUploading(false);
        }
    };

    const props = {
        onRemove: (file, fileList) => {
            setFileCSV(null);
            setMyFileList(null);
            return false;
        },
        beforeUpload: (file, fileList) => {
            if (file.type === "text/csv") {
                setFileCSV(file);
                setMyFileList(fileList);
            } else {
                message.error('We accept only .csv files');
            }
            return false;
        },
    };

    useEffect(() => {

        if (fileCSV) {
            // This fields will be sending to API
            formData.append('employees_file', fileCSV);
            formData.append('employer_id', employerData?.id);
        }
        // eslint-disable-next-line
    }, [fileCSV, employerData?.id])

    return <>
        {/*register errors modal window*/}{contextHolder}{/*register errors modal window*/}

        <Button
            size={'small'}
            onClick={showModal}
            hidden={activeContract === undefined || activeContract === null}  // the contract is not active
        >
            <UploadOutlined/>&nbsp;Upload employees
        </Button>


        <Modal
            footer={null}
            width={window.innerWidth > 1200 ? "40%" : '100%'}
            title={`Upload employees for ${employerData?.name}`}
            open={open}
            onCancel={handleFileModalCancel}
        >
            <p>
                We can loading only employees with unique names
            </p>

            <Upload {...props} maxCount={1} fileList={myFileList} accept={'.csv'}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </Upload>

            <Button
                name={'employees_file'}
                type="primary"
                onClick={handleUpload}
                disabled={!fileCSV}
                loading={uploading}
                style={{
                    marginTop: 16,
                }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>


        </Modal>
    </>
        ;
}

export default EmployerEmployeesDownloaderModal;