import restAPI from "../../../features/restAPI";
import dayjs from "dayjs";
import React, {useContext, useState, useEffect} from "react";


import {LoadingOutlined} from "@ant-design/icons";
import {Spin, Card, Col, Row, Statistic} from "antd";
import {Area} from '@ant-design/charts';

import {AuthContext} from "../../auth/AuthContext";
import {authPages} from "../../../routing/config";
import MainPageTitle from "../../../components/MainPageTitle";

const Dashboard = () => {
    const {home} = authPages;

    const currentDate = dayjs();

    const [isLoading, setIsLoading] = useState(true);
    const {authContext} = useContext(AuthContext); // auth data from current session

    const [dashboardData, setDashboardData] = useState(null);


    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const loadDashboardData = async () => {
        try {
            const apiUrl = `/employer-dashboard/`;
            const response = await restAPI.get(apiUrl, authConfig);
            setDashboardData(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await loadDashboardData();
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);


    // // Ant Design Charts
    // const data = [
    //     {month: 'January', spend: 100, kit: "Micronutrient"},
    //     {month: 'January', spend: 200, kit: "Colon Cancer"},
    //     {month: 'January', spend: 0, kit: "Food Sensitivity"},
    //     {month: 'January', spend: 100, kit: "Food Sensitivity Expanded"},
    //     {month: 'January', spend: 900, kit: "PCR"},
    //     {month: 'January', spend: 0, kit: "STD"},
    //
    //     {month: 'February', spend: 1000, kit: "Micronutrient"},
    //     {month: 'February', spend: 700, kit: "Colon Cancer"},
    //     {month: 'February', spend: 100, kit: "Food Sensitivity"},
    //     {month: 'February', spend: 1000, kit: "Food Sensitivity Expanded"},
    //     {month: 'February', spend: 200, kit: "PCR"},
    //     {month: 'February', spend: 200, kit: "STD"},
    //
    //     {month: 'March', spend: 500, kit: "Micronutrient"},
    //     {month: 'March', spend: 750, kit: "Colon Cancer"},
    //     {month: 'March', spend: 300, kit: "Food Sensitivity"},
    //     {month: 'March', spend: 4000, kit: "Food Sensitivity Expanded"},
    //     {month: 'March', spend: 1000, kit: "PCR"},
    //     {month: 'March', spend: 200, kit: "STD"},
    // ];

    const config = {
        data: dashboardData !== null ? [...dashboardData?.chart_data] : [],
        padding: 'auto',
        xField: 'month',
        yField: 'spend',
        seriesField: 'kit',
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#01a9ac',
                lineWidth: 2,
            },
        },
        xAxis: {
            label: {
                formatter: (v) =>
                    `${v}, 2023`
            },
        },
        yAxis: {
            label: {
                formatter: (v) =>
                    `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) =>
                        `$ ${s},`
                    ),
            },
        },
        meta: {
            month: {alias: 'Month'},
            spend: {alias: '$, spend'},
        },
        legend: {
            position: 'top',
        },
    };
    // Ant Design Charts END

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row style={{padding: "0 10px"}} gutter={16}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{home.title}</MainPageTitle>
            </Col>

            {!isLoading && dashboardData?.total_employees_spending !== 0
                ? <Col xs={24} lg={16}>
                    <Area {...config} />
                    {/*<Line {...config} />*/}
                </Col>

                : <Col xs={24} lg={16}>
                    At this moment, there is no data to display. <br/>
                    After the employee makes a purchase, the data will be
                    displayed here.
                </Col>
            }

            <Col xs={24} lg={8}>

                <Row gutter={[16, 16]}>

                    <Col span={12}>
                        <Card bordered={true} hoverable={true}>
                            <Statistic
                                title="Total spent"
                                value={dashboardData?.total_employees_spending}
                                precision={2}
                                valueStyle={{
                                    color: '#01a9ac',
                                }}
                                prefix={"$"}
                            />
                        </Card>
                    </Col>


                    <Col span={12}>
                        <Card bordered={true} hoverable={true}>
                            <Statistic
                                title="Contract expires in"
                                value={`${dayjs(dashboardData?.contract_finish).diff(currentDate, 'day')} days`}
                                valueStyle={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    color: '#01a9ac',
                                }}
                            />
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Card bordered={true} hoverable={true}>
                            <Statistic
                                title="Popular Test Kit"
                                value={dashboardData?.total_employees_spending !== 0 ? dashboardData?.popular_test_kit : "No data"}
                                valueStyle={{
                                    color: '#01a9ac',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            />
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card bordered={true} hoverable={true}>
                            <Statistic
                                title="Active Accounts"
                                value={dashboardData?.total_active_employees}
                                valueStyle={{
                                    color: '#01a9ac',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            />
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card bordered={true} hoverable={true}>
                            <Statistic
                                title="Uploaded Employees"
                                value={dashboardData?.total_employees}
                                valueStyle={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    color: '#01a9ac',
                                }}
                            />
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Card bordered={true} hoverable={true}>
                            <Statistic
                                title="Employees Using"
                                value={dashboardData?.total_employees_who_buy_in_percentage}
                                suffix="%"
                                valueStyle={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    color: '#01a9ac',
                                }}
                            />
                        </Card>
                    </Col>

                </Row>

            </Col>
        </Row>
    </Spin>
}

export default Dashboard;