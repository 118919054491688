import daysjs from "dayjs";
import restAPI from "../../../features/restAPI";
import React, {useEffect, useState} from 'react';

import {EditOutlined} from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    message,
    Modal,
    Select,
    DatePicker,
    Switch,
    Row,
    Col,
    Typography, Divider,
} from 'antd';
import DeleteEmployerWithPatientAccountButton from "./DeleteEmployerWithPatientAccountButton";

const {Option} = Select;

const phoneNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? "-" + x[3] : ""}`);
}


const EmployeeEditModal = props => {
    // All fields from API
    const {
        id, first_name, last_name, middle_name, gender, email, phone, date_of_birth, client, role, active,
        authConfig, update, removeEmployeeFromListAfterDelete
    } = props;

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // fix bug on mobile devices with scroll
    if (open) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
            triggerSubmitHandler();
        }, 1000);
    };

    const submitForm = async (formData) => {
        // formData.client = clientID;

        await restAPI.put(`/employer-employees/${id}/`, formData, authConfig)
            .then(response => {
                update(prevState =>
                    prevState.map(employee =>
                        employee?.id === id ? response?.data : employee));
            })
            .catch(err => console.log(err));
    };

    const onFinish = async values => {

        try {
            setIsLoading(false);

            if (values.date_of_birth === null) {
                throw new Error('Date of birth is required!');
            }

            const formData = {
                ...values,
                date_of_birth: daysjs(values.date_of_birth).format('YYYY-MM-DD'),
            }

            console.log(formData);

            await submitForm(formData);

            message.success({
                content: 'Client info updated!',
                duration: 2,
            });

            setOpen(false);

        } catch (error) {

            console.log(error);

            message.error({
                content: 'We have an errors!',
                duration: 2,
            });

        } finally {
            setIsLoading(false);
        }
    }

    const triggerSubmitHandler = () => form.submit();

    useEffect(() => {
        form.setFieldsValue({
            id,
            first_name,
            last_name,
            middle_name,
            gender,
            phone: phone === null ? null : phoneNumberMask(phone),
            email,
            date_of_birth: date_of_birth === null ? null : daysjs(date_of_birth, 'MM-DD-YYYY'),
            client,
            role,
            active
        });
    }, [form, ...Object.values(props)]);

    return <>
        <Button size={'small'} onClick={showModal}>
            <EditOutlined/>&nbsp;Edit
        </Button>


        <Modal
            title={`Edit employee: ${first_name} ${last_name}`}
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            okText={'Update info'}
            destroyOnClose={true}
        >

            <Form
                form={form}
                name="editEmployeeForm"
                onFinish={onFinish}
            >
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Form.Item name="client" label="Employer ID:">
                            <Input size={'small'} disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="id"
                            label="Employee ID"
                            disabled={true}
                        >
                            <Input disabled={true} size={'small'}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="role"
                    label="Role"
                    rules={[{required: true, message: 'Please enter the role'}]}
                >
                    <Select size={'small'}>
                        <Option value="Patient">Patient</Option>
                        <Option value="Finance">Finance</Option>
                        <Option value="Super">Super</Option>
                        <Option value="HR">HR</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[{required: true, message: 'Please enter the first name'}]}
                >
                    <Input size={'small'}/>
                </Form.Item>

                <Form.Item
                    name="middle_name"
                    label="Middle Name"
                >
                    <Input size={'small'}/>
                </Form.Item>

                <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[{required: true, message: 'Please enter the last name'}]}
                >
                    <Input size={'small'}/>
                </Form.Item>


                <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{required: true, message: 'Please select the gender'}]}
                >
                    <Select size={'small'}>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {required: true, message: 'Please enter the email'},
                        {type: 'email', message: 'Please enter a valid email'},
                    ]}
                >
                    <Input size={'small'}/>
                </Form.Item>


                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                        {required: true, message: 'Please input phone!'},
                        {
                            pattern: /(\d{0,3})(\d{0,3})(\d{0,4})/,
                            message: "Not valid",
                            transform: v => form.setFieldValue('phone', phoneNumberMask(v)),
                        }
                    ]}
                >
                    <Input size={'small'}/>
                </Form.Item>

                <Form.Item
                    name="date_of_birth"
                    label="Date of Birth"
                    rules={[{required: true, message: 'Please select the date of birth'}]}
                >
                    <DatePicker
                        size={'small'}
                        format={'MM-DD-YYYY'}
                    />
                </Form.Item>

                <Form.Item name="active" label="Active" valuePropName="checked">
                    <Switch/>
                </Form.Item>

                <Typography.Text type="secondary">
                    If you disable this employee, they will not be able to log in as Patient
                </Typography.Text>

                <Divider/>

                <DeleteEmployerWithPatientAccountButton
                    userID={props?.auth_user}
                    removeEmployeeFromListAfterDelete={removeEmployeeFromListAfterDelete}
                    authConfig={props?.authConfig}
                />
            </Form>

        </Modal>

    </>;
}

export default EmployeeEditModal;