import {authPages, notAuthPages} from "./config";

import React, {useContext, useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import MainLayout from "../MainLayout";
import {AuthContext} from "../pages/auth/AuthContext";


const getPageTitle = (isAuth, location, authPages, notAuthPages) => {
    /* Match location.pathname with pages objects in depends on auth context */

    let page = null;

    if (isAuth === null) {
        page = Object.values(notAuthPages).find(p =>
            p.hasOwnProperty('title') && p.url === location.pathname
        );
    }


    if (isAuth !== null) {
        page = Object.values(authPages).find(p =>
            p.hasOwnProperty('title') && p.url === location.pathname
        );
    }

    return page;
}


const Root = () => {
    const location = useLocation();
    const {authContext} = useContext(AuthContext);

    // eslint-disable-next-line
    const {
        employees,
        home,
        myInformation,
        notFoundPage,
    } = authPages;

    const {
        login,
        logout,
        resetPassword,
    } = notAuthPages;

    useEffect(() => {
        try {
            const currentPageTitle = getPageTitle(authContext, location, authPages, notAuthPages);
            document.title = `${currentPageTitle?.title} | Employer portal | EFunctional`;
        } catch (err) {
            // Do nothing.
        }
    }, [location, authContext]);

    return <Routes>
        <Route element={<MainLayout/>}>
            {/*Basic pages*/}
            <Route path={login.url} element={login.jsx}/>
            <Route path={resetPassword.url} element={resetPassword.jsx}/>
            <Route path={notFoundPage.url} element={notFoundPage.jsx}/>

            <Route path={logout.url} element={logout.jsx}/>

            {/*Basic pages*/}


            {/* Auth required pages */}
            <Route path={home.url} element={home.jsx}/>
            <Route path={employees.url} element={employees.jsx}/>
            <Route path={myInformation.url} element={myInformation.jsx}/>
            {/* Auth required pages */}
        </Route>
    </Routes>;
}

export default Root;