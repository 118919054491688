import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider, App} from 'antd';


/*-------Font and Ant-Design styles--------*/
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import 'antd/dist/reset.css';
/*-----------------------------------------*/

/*-----------Application Providers---------*/
import {AuthProvider} from "./entrypoint/pages/auth/AuthProvider";
import {TaskStatusProvider} from "./entrypoint/pages/tasks/TaskStatusContext";
/*-----------------------------------------*/

import Root from "./entrypoint/routing/router";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <AuthProvider>
            <TaskStatusProvider>
                <BrowserRouter basename={'/'}>
                    <ConfigProvider theme={{token: {colorPrimary: '#01a9ac'}}}>
                        <App>
                            <Root/>
                        </App>
                    </ConfigProvider>
                </BrowserRouter>
            </TaskStatusProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
