import restAPI from "../../../features/restAPI";
import React, {useContext, useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import {AuthContext} from "../AuthContext";
import {authPages, notAuthPages} from "../../../routing/config";

import loginLogo from "./login_logo.svg";
import bg from "./efunc_bg.svg";

import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {Row, Col, Form, Input, Alert} from "antd";

import {SubmitButton, ForgotPasswordButton, LogoElement, LoginForm} from "../../../components/Login";

const Login = () => {
    /*
        Login component. This is the first page in app, when user not auths.
        All USER LOGIN logic you can find in AuthContext and know how to work the login and Auth system as well.
    */

    const {resetPassword,} = notAuthPages;
    const navigate = useNavigate();
    const {authContext, login, login_for_employee_with_role} = useContext(AuthContext);

    const [authError, setAuthError] = useState(null);  // state for login error
    const [isLogin, setIsLogin] = useState(authContext);  // local component state for auth

    const onSubmitHandler = async values => {
        try {

            // clear errors is we have
            setAuthError(null);

            const response = await restAPI.post('/employer-portal-auth/', values);

            // console.log(response);

            // Employee with Role login now. Backend return `employer_data` and `employee_data` in one object
            if (response?.data.hasOwnProperty('employer_data') && response?.data.hasOwnProperty('employee_data')) {
                const {access, refresh, employer_data, employee_data} = response.data;
                login_for_employee_with_role({access, refresh}, {...employer_data}, {...employee_data});
                setIsLogin(true);  // update Auth state in the component, for redirect
            }

            // Employer login. Backend return `auth_user` in one object by default for Employer
            if (response?.data.hasOwnProperty('auth_user')) {
                const {access, refresh, auth_user} = response.data;
                login({access, refresh}, {...auth_user});  // save to AuthProvider
                setIsLogin(true);  // update Auth state in the component, for redirect
            }


        } catch (error) {

            // These errors we can get from API
            if (error?.response?.data.hasOwnProperty('error')) {
                setAuthError(error?.response?.data?.error);
                return;
            }

            // Check where we can manage this error
            if (error?.response?.data.hasOwnProperty('detail')) {
                setAuthError(error.response?.data?.detail);
            }
        }
    }


    // Login redirect controller
    useEffect(() => {
        navigate('/');  // These need it for control all-other links, when we are not AUTH yet

        if (isLogin) {
            document.title = authPages.home.title;
            navigate(authPages.home.url);  // move to the main page after successful auth
        }

        // eslint-disable-next-line
    }, [navigate, isLogin]);

    return <Row justify={"center"} style={{background: `url('${bg}')`, backgroundSize: 'cover', height: "100vh"}}>

        <Col xs={20} sm={16} md={12} lg={10} xl={8} xxl={6}>

            <LoginForm
                autoComplete={'true'}
                name="basic"
                initialValues={{remember: true}}
                onFinish={onSubmitHandler}
            >
                <Row justify={"center"}>
                    <LogoElement src={loginLogo} alt=""/>
                </Row>

                <Form.Item name="username" rules={[{required: true, message: 'Please input your Username!',},]}>
                    <Input
                        placeholder="Username (email)"
                        style={{borderRadius: 2, marginBottom: 0}}
                        prefix={<UserOutlined style={{color: "#169F8A"}}/>}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{
                        required: true, message: 'Please input your Password!',
                    },]}
                >
                    <Input.Password
                        placeholder="Password"
                        style={{borderRadius: 2}}
                        prefix={<LockOutlined style={{color: "#169F8A"}}/>}
                    />
                </Form.Item>

                <Form.Item>
                    <SubmitButton htmlType="submit" type="primary">Log In</SubmitButton>
                </Form.Item>

                <Row justify={"center"}>
                    <NavLink to={resetPassword.url} style={{color: 'white'}}>
                        <ForgotPasswordButton type={"link"}>
                            Forgot password?
                        </ForgotPasswordButton>
                    </NavLink>
                </Row>

            </LoginForm>


            {authError && <Alert
                style={{marginTop: 20, fontFamily: "Montserrat, sans-serif", fontSize: 14}}
                message={authError}
                type="error"
                closable
            />}


        </Col>
    </Row>;

}

export default Login;