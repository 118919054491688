/*
* This file it's main router config. All urls and components for this urls, texts for title and others in the future.
* Use this file firstly for creating new page.
* */
import NotFoundPage from "../pages/base/NotFoundPage";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import Logout from "../pages/auth/Logout";

import {AuthRequired} from "../pages/auth/AuthProvider";
import Dashboard from "../pages/application/Dashboard";
import EmployeesManagementEmails from "../pages/application/EmployeesManagementEmails";
import MyInformation from "../pages/application/MyInformation";


const notAuthPages = {

    'login': {
        url: '/login',
        title: 'Login',
        jsx: <Login/>,
    },

    'login1': {
        url: '/login/',
        title: 'Login',
        jsx: <Login/>,
    },

    'loginDefault': {
        url: '/',
        title: 'Login',
        jsx: <Login/>,
    },

    'resetPasswordDefault': {
        url: '/reset-password/',
        title: 'Reset password',
        jsx: <ResetPassword/>,
    },

    'resetPassword': {
        url: '/reset-password',
        title: 'Reset password',
        jsx: <ResetPassword/>,
    },

    'logout': {
        url: '/logout',
        title: 'Logout',
        jsx: <Logout/>,
    },


    // Using it for not auth guys who go to different pages
    'notFoundPageForNotAuth': {
        url: '*',
        title: 'Page not found. 404',
        jsx: <NotFoundPage/>,
    },
};

const authPages = {


    'notFoundPage': {
        url: '*',
        title: 'Page not found. 404',
        jsx: <NotFoundPage/>,
    },

    'home': {
        url: '/',
        title: 'Dashboard',
        jsx: <AuthRequired><Dashboard/></AuthRequired>,
    },

    'employees': {
        url: '/employees-list',
        title: 'Employees',
        jsx: <AuthRequired><EmployeesManagementEmails/></AuthRequired>,
    },

    'myInformation': {
        url: '/my-information',
        title: 'Information',
        jsx: <AuthRequired><MyInformation/></AuthRequired>,
    },


};

export {notAuthPages, authPages} ;